import { Observable, Subscriber } from 'rxjs';
import * as paper from 'paper';
import {
  collection,
  DocumentSnapshot,
  FirestoreDataConverter,
  Timestamp,
} from 'firebase/firestore';
import { firesetore } from 'src/app/firebase.app';
import { Point } from 'paper/dist/paper-core';

export interface Point {
  x: number;
  y: number;
}

export enum Color {
  BLACK = 'black',
  YELLOW = 'yellow',
  RED = 'red',
  TRANSPARENT = 'transparent',
  GREY = 'grey',
  TRANSPARENT_RED = '#ff000055',
}

export enum Tool {
  READONLY = 'readonly',
  PENCIL = 'pencil',
  ERASER = 'eraser',
  CUT = 'cut',
}

export interface Line {
  points?: Point[];
  strokeStyle: Color;
  lineWidth: number;
  simplify: number;
  boundingRect?: BoundingRect;
  created: Timestamp;
}

export interface BoundingRect {
  top: number;
  bottom: number;
  left: number;
  right: number;
}

export const linesRef = collection(firesetore, 'line');

export const lineConverter: FirestoreDataConverter<Line> = {
  toFirestore: (line: Line) => ({
    created: line.created,
    lineWidth: line.lineWidth,
    simplify: line.simplify,
    strokeStyle: line.strokeStyle,
    points: line.points.map((p: Point) => ({ x: p.x, y: p.y })),
    boundingRect: line.boundingRect,
  }),
  fromFirestore: (snapshot: DocumentSnapshot) => {
    const data = snapshot.data();
    return {
      strokeStyle: data.strokeStyle,
      created: data.created,
      points: data.points?.map((p: Point) => new Point(p.x, p.y)),
      simplify: data.simplify,
      lineWidth: data.lineWidth,
      boundingRect: data.boundingRect,
    };
  },
};

export const toPath = (line: Line, resizeFactor = 1): paper.Path =>
  new paper.Path({
    segments: line.points.map(
      (point: Point) =>
        new paper.Point(point.x * resizeFactor, point.y * resizeFactor)
    ),
    strokeColor: getLineColor(line),
    strokeWidth: line.lineWidth * resizeFactor,
  });

const isDarkModeEnabled = (): boolean =>
  window.matchMedia('(prefers-color-scheme: dark)').matches;

export const darkModePrefChange$ = new Observable<boolean>(
  (sub: Subscriber<boolean>) => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => sub.next(e.matches));
  }
);

export const getLineColor = (line: Line): string => {
  if (line.strokeStyle === Color.BLACK && isDarkModeEnabled()) {
    return 'white';
  }
  return line.strokeStyle;
};

// eslint-disable-next-line complexity
export const getBoundingRect = (
  line: Line
): { top: number; bottom: number; left: number; right: number } => {
  let bottom = 0;
  let top: number;
  let left: number;
  let right = 0;
  for (const point of line.points) {
    if (point.y > bottom) {
      bottom = point.y;
    }
    if (point.y < top || top === undefined) {
      top = point.y;
    }
    if (point.x > right) {
      right = point.x;
    }
    if (point.x < left || left === undefined) {
      left = point.x;
    }
  }
  return {
    right,
    left,
    top,
    bottom,
  };
};

export const linesEqual = (a: Line, b: Line): boolean =>
  JSON.stringify(getLineJson(a)) === JSON.stringify(getLineJson(b));

const getLineJson = (line: Line): unknown => ({
  boundingRect: {
    top: line.boundingRect.top,
    bottom: line.boundingRect.bottom,
    left: line.boundingRect.left,
    right: line.boundingRect.right,
  },
  strokeStyle: line.strokeStyle,
  simplify: line.simplify,
  lineWidth: line.lineWidth,
  created: line.created.toString(),
});
