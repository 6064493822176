import {
  collection,
  DocumentSnapshot,
  FirestoreDataConverter,
  Timestamp,
} from 'firebase/firestore';
import { firesetore } from '../firebase.app';
import { Line } from '../shared/definitions';

export interface Note {
  slug?: string;
  title?: string;
  icon?: string;
  lines: Line[];
  locked?: boolean;
  created?: Timestamp;
  updated?: Timestamp;
}

export const FIRESTORE_LIMIT_MAX = 10000;

export const notesRef = collection(firesetore, 'note');

export const noteConverter: FirestoreDataConverter<Note> = {
  toFirestore: (note: Note) => ({
    slug: note.slug,
    title: note.title,
    icon: note.icon,
    locked: !!note.locked,
    created: new Timestamp(note.created.seconds, note.created.nanoseconds),
    updated: new Timestamp(note.updated.seconds, note.updated.nanoseconds),
  }),
  fromFirestore: (snapshot: DocumentSnapshot<Note>) => {
    const data = snapshot.data();
    return {
      slug: data.slug,
      title: data.title,
      icon: data.icon ?? getIcon(data.slug),
      lines: [],
      locked: !!data.locked,
      created: data.created,
      updated: data.updated,
    };
  },
};

export const possibleIcons = [
  'alarm',
  'alert',
  'american-football',
  'analytics',
  'aperture',
  'apps',
  'archive',
  'attach',
  'barcode',
  'baseball',
  'basket',
  'basketball',
  'battery-full',
  'beaker',
  'beer',
  'bicycle',
  'boat',
  'body',
  'bonfire',
  'book',
  'briefcase',
  'brush',
  'bug',
  'build',
  'bulb',
  'bus',
  'cafe',
  'calculator',
  'call',
  'camera',
  'car',
  'card',
  'cart',
  'cash',
  'chatbubbles',
  'clipboard',
  'cloud',
  'cloudy-night',
  'code',
  'color-fill',
  'color-palette',
  'color-wand',
  'compass',
  'construct',
  'copy',
  'cube',
  'egg',
  'finger-print',
  'flag',
  'flame',
  'flash',
  'flask',
  'flower',
  'folder',
  'football',
  'game-controller',
  'glasses',
  'hammer',
  'hand-left',
  'happy',
  'headset',
  'heart',
  'help-buoy',
  'home',
  'ice-cream',
  'images',
  'jet',
  'key',
  'laptop',
  'leaf',
  'link',
  'lock',
  'magnet',
  'mail',
  'man',
  'map',
  'medal',
  'medkit',
  'megaphone',
  'mic',
  'moon',
  'musical-note',
  'musical-notes',
  'nuclear',
  'nutrition',
  'paper-plane',
  'paw',
  'pie-chart',
  'pint',
  'pizza',
  'airplane',
  'planet',
  'podium',
  'pricetags',
  'print',
  'pulse',
  'rainy',
  'recording',
  'restaurant',
  'ribbon',
  'rose',
  'sad',
  'school',
  'shirt',
  'snow',
  'speedometer',
  'star',
  'stats-chart',
  'subway',
  'sunny',
  'tennisball',
  'text',
  'thermometer',
  'thumbs-up',
  'thumbs-down',
  'thunderstorm',
  'time',
  'train',
  'transgender',
  'trophy',
  'umbrella',
  'logo-usd',
  'walk',
  'water',
  'wine',
  'woman',
];

export const getIcon = (slug: string): string => {
  let sum = 0;
  for (const char of slug) {
    sum += char.charCodeAt(0);
  }
  return possibleIcons[sum % possibleIcons.length];
};
