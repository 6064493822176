/* eslint-disable complexity */
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  Color,
  darkModePrefChange$,
  getBoundingRect,
  Line,
  Point,
  Tool,
  toPath,
} from '../definitions';
import * as paper from 'paper';
import { AlertController } from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'draw-canvas',
  templateUrl: './draw-canvas.component.html',
  styleUrls: ['./draw-canvas.component.scss'],
})
export class DrawCanvasComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() scrollOffsetY = 0;
  @Input() tool: Tool = Tool.READONLY;
  @Input() lineWidth = 1;
  @Input() simplify = 10;
  @Input() lines: Line[] = [];
  @Output() lineAdded = new EventEmitter<Line>();
  @Output() lineRemoved = new EventEmitter<Line[]>();
  @ViewChild('canvas') private canvas: ElementRef;

  height = 3000;

  private nativeCanvas: HTMLCanvasElement;
  private offsetX: 0;

  private currentLine!: Line;
  private currentPath!: paper.Path;

  private ngOnDestroy$ = new Subject();

  private strokeStyleValue: Color = Color.BLACK;

  constructor(
    private element: ElementRef,
    private alertCtrl: AlertController
  ) {}

  get editable(): boolean {
    return (
      this.tool === Tool.PENCIL ||
      this.tool === Tool.ERASER ||
      this.tool === Tool.CUT
    );
  }

  get strokeStyle(): Color {
    if (this.tool === Tool.ERASER) {
      return Color.TRANSPARENT_RED;
    }
    if (this.tool === Tool.CUT) {
      return Color.GREY;
    }
    return this.strokeStyleValue;
  }

  @Input() set strokeStyle(value: Color) {
    this.strokeStyleValue = value;
  }

  ngOnInit(): void {
    darkModePrefChange$
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() => this.draw());
  }

  async ngAfterViewInit(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 100));
    this.nativeCanvas = this.canvas.nativeElement;
    this.nativeCanvas.width = this.element.nativeElement.offsetWidth;
    this.nativeCanvas.height = this.height;
    this.offsetX = this.element.nativeElement.getBoundingClientRect().left;
    paper.setup(this.nativeCanvas);
    this.draw();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  onMouseDown(event: MouseEvent | TouchEvent): void {
    if (
      !this.editable ||
      !this.nativeCanvas ||
      (event instanceof TouchEvent && event.touches.length > 1)
    ) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.currentLine = {
      strokeStyle: this.strokeStyle,
      lineWidth: this.lineWidth,
      points: [this.getPoint(event)],
      simplify: this.simplify,
      created: Timestamp.now(),
    };
    this.currentPath = toPath(this.currentLine);
  }

  onMouseMove(event: MouseEvent | TouchEvent): void {
    if (
      !this.editable ||
      !this.nativeCanvas ||
      !this.currentLine ||
      !this.currentPath ||
      (event instanceof TouchEvent && event.touches.length > 1)
    ) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    this.drawNextPoint(this.getPoint(event));
  }

  onMouseOut(): void {
    if (!this.editable || !this.nativeCanvas || !this.currentLine) {
      return;
    }
    switch (this.tool) {
      case Tool.PENCIL:
        this.onFinishLinePencil();
        break;
      case Tool.ERASER:
        this.onFinishLineErase();
        break;
      case Tool.CUT:
        this.onFinishLineCut();
        break;
    }
    delete this.currentLine;
    delete this.currentPath;
  }

  onFinishLinePencil(): void {
    this.currentLine.boundingRect = getBoundingRect(this.currentLine);
    this.lines.push(this.currentLine);
    this.lineAdded.next(this.currentLine);
    this.simplifyCurrentPath();
  }

  onFinishLineErase(): void {
    this.eraseLines();
  }

  async onFinishLineCut(): Promise<void> {
    const alert = await this.alertCtrl.create({
      message: 'not implemented',
      buttons: [
        {
          text: 'Ok',
          handler: (): void => this.redraw(),
        },
      ],
    });
    await alert.present();
  }

  private redraw(): void {
    paper.project.clear();
    this.draw();
  }

  private draw(): void {
    for (const line of this.lines) {
      this.drawLine(line);
    }
    paper.view.update();
  }

  private drawLine(line: Line): void {
    const path = toPath(line);
    this.simplifyPath(path, line.simplify);
  }

  private drawNextPoint(newPoint: Point): void {
    if (!this.currentLine || !this.currentPath) {
      throw new Error('No line in progress');
    }
    this.currentLine.points.push(newPoint);
    this.currentPath.add(new paper.Point(newPoint.x, newPoint.y));
    paper.view.update();
  }

  private eraseLines(): void {
    const lines = [];
    const removedLines = [];
    for (const line of this.lines) {
      const path = toPath(line);
      if (!path.intersects(this.currentPath)) {
        lines.push(line);
      } else {
        removedLines.push(line);
      }
    }
    this.lines = lines;
    if (removedLines.length > 0) {
      this.lineRemoved.next(removedLines);
    }
    this.redraw();
  }

  private getPoint(event: MouseEvent | TouchEvent): Point {
    if (event instanceof TouchEvent) {
      return {
        x: event.touches[0].clientX - this.offsetX,
        y: event.touches[0].clientY + this.scrollOffsetY,
      };
    }
    return {
      x: event.clientX - this.offsetX,
      y: event.clientY + this.scrollOffsetY,
    };
  }

  private simplifyCurrentPath(): void {
    if (!this.currentLine || !this.currentPath) {
      throw new Error('No line in progress');
    }
    this.simplifyPath(this.currentPath, this.currentLine.simplify);
  }

  private simplifyPath(path: paper.Path, level: number): void {
    if (level > 0) {
      path.simplify(level);
    }
  }
}
