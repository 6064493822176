import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule } from '@angular/router';
import { NfTalesRoute } from './core/strategies/nftales-route-reuse.strategy';
import { NotePageModule } from './note/note.module';

const routes: NfTalesRoute[] = [
  {
    path: '',
    redirectTo: 'note/',
    pathMatch: 'full',
  },
  {
    path: 'note',
    loadChildren: (): Promise<typeof NotePageModule> =>
      import('./note/note.module').then((m) => m.NotePageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
