import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Storage } from '@capacitor/storage';
import { ModalController, ToastController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Tool } from '../../shared/definitions';
import { NoteSettingsModalComponent } from '../modals/note-settings-modal/note-settings-modal.component';

@Injectable({
  providedIn: 'root',
})
export class NoteSettingsService {
  drawForm = new FormGroup({
    tool: new FormControl<Tool>(Tool.READONLY),
    pencil: new FormGroup({
      simplify: new FormControl<number>(3),
      strokeWidth: new FormControl<number>(2),
    }),
    eraser: new FormGroup({
      simplify: new FormControl<number>(0),
      strokeWidth: new FormControl<number>(5),
    }),
  });

  tools = Tool;

  drawCanvasSettings: {
    simplify: number;
    strokeWidth: number;
    scrollPosY: number;
  };

  constructor(
    private toastCtrl: ToastController,
    private modalCtrl: ModalController
  ) {}

  get tool(): Tool {
    return this.drawForm.get('tool').value;
  }

  get changes$(): Observable<unknown> {
    return this.drawForm.valueChanges.pipe(
      map(() => this.getDrawCanvasSettings()),
      tap((settings) => (this.drawCanvasSettings = settings)),
      tap(() => this.writeDrawCanvasSettingsToStorage())
    );
  }

  async init(): Promise<void> {
    await this.loadDrawCanvasSettingsFromStorage();
    this.drawCanvasSettings = this.getDrawCanvasSettings();
    this.changes$.subscribe();
  }

  onScroll(event: any): void {
    this.drawCanvasSettings.scrollPosY = event.detail.currentY;
  }

  async loadDrawCanvasSettingsFromStorage(): Promise<void> {
    const settings = await Storage.get({
      key: 'draw-settings',
    });
    if (!settings?.value) {
      return;
    }
    try {
      const settingsValue = JSON.parse(settings.value);
      this.drawForm.setValue(settingsValue);
      this.drawForm.get('tool').setValue(Tool.READONLY);
    } catch (error) {
      const toast = await this.toastCtrl.create({
        header: 'Settings are corrupted - resetting default',
        message: error.message,
        duration: 1000,
      });
      await toast.present();
    }
  }

  async openSettingsModal(): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: NoteSettingsModalComponent,
      componentProps: {
        settingsForm: this.drawForm,
      },
    });
    await modal.present();
  }

  async writeDrawCanvasSettingsToStorage(): Promise<void> {
    await Storage.set({
      key: 'draw-settings',
      value: JSON.stringify(this.drawForm.value),
    });
  }

  getDrawCanvasSettings(): {
    simplify: number;
    strokeWidth: number;
    scrollPosY: number;
  } {
    if (this.tool === Tool.ERASER) {
      return {
        simplify: this.drawForm.get('eraser.simplify').value,
        strokeWidth: this.drawForm.get('eraser.strokeWidth').value,
        scrollPosY: this.drawCanvasSettings?.scrollPosY ?? 0,
      };
    }
    return {
      simplify: this.drawForm.get('pencil.simplify').value,
      strokeWidth: this.drawForm.get('pencil.strokeWidth').value,
      scrollPosY: this.drawCanvasSettings?.scrollPosY ?? 0,
    };
  }
}
