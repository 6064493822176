import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { NoteNewComponent } from './pages/note-new/note-new.component';
import { NoteListPageComponent } from './pages/note-list-page/note-list-page.component';
import { NotesSwipePageComponent } from './pages/notes-swipe-page/notes-swipe-page.component';
import { NfTalesRoute } from '../core/strategies/nftales-route-reuse.strategy';
import { NotePageComponent } from './pages/note-page/note-page.component';

const routes: NfTalesRoute[] = [
  {
    path: 'new',
    component: NoteNewComponent,
  },
  {
    path: 'all',
    component: NoteListPageComponent,
  },
  {
    path: 'edit/:note',
    component: NotePageComponent,
  },
  {
    path: ':note',
    component: NotesSwipePageComponent,
    data: {
      reuse: 'carousel',
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/note/',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class NotePageRoutingModule {}
