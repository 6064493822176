import { Component, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NoteListComponent } from './note/components/note-list/note-list.component';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild('noteList') noteList: NoteListComponent;

  showCreate = !!environment.edit;

  moreLoading = false;

  async onClickLoadMoreButton(): Promise<void> {
    this.moreLoading = true;
    await this.noteList.loadNextPage();
    this.moreLoading = false;
  }
}
