import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  Route,
  RouteReuseStrategy,
} from '@angular/router';

export interface NfTalesData {
  [name: string]: any;
  reuse?: string;
}

export declare type NfTalesRoute = Omit<Route, 'data'> & { data?: NfTalesData };

export class NfTalesRouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  public shouldDetach(): boolean {
    return false;
  }

  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null
  ): void {
    if (!handle) {
      return;
    }
    const key = (route.routeConfig as NfTalesRoute)?.data?.reuse;
    this.storedRoutes.set(key, handle);
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const key = (route.routeConfig as NfTalesRoute)?.data?.reuse;
    return typeof key === 'string' && this.storedRoutes.has(key);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const key = (route.routeConfig as NfTalesRoute)?.data?.reuse;
    return this.storedRoutes.get(key) ?? null;
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}
