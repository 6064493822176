import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import {
  BoundingRect,
  darkModePrefChange$,
  Line,
  toPath,
} from '../definitions';
import * as paper from 'paper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-canvas',
  templateUrl: './canvas.component.html',
  styleUrls: ['./canvas.component.scss'],
})
export class CanvasComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() lines: Line[] = [];
  @ViewChild('canvas') private canvas: ElementRef;

  private resizeFactor = 1;

  private nativeCanvas: HTMLCanvasElement;
  private ngOnDestroy$ = new Subject();

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    darkModePrefChange$
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(() => this.draw());
  }

  async ngAfterViewInit(): Promise<void> {
    await new Promise((resolve) => setTimeout(resolve, 100));
    this.nativeCanvas = this.canvas.nativeElement;
    const fullWidth = this.element.nativeElement.offsetWidth;
    const rect = this.getBoundingRect();
    this.resizeFactor = fullWidth / (rect.right + 10);
    if (this.resizeFactor > 1) {
      this.resizeFactor = 1;
    }
    this.nativeCanvas.width = fullWidth;
    this.nativeCanvas.height = rect.bottom * this.resizeFactor + 1000;
    paper.setup(this.nativeCanvas);
    this.draw();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  // eslint-disable-next-line complexity
  private getBoundingRect(): BoundingRect {
    const rect = {
      top: Number.MAX_SAFE_INTEGER,
      bottom: 0,
      left: Number.MAX_SAFE_INTEGER,
      right: 0,
    };
    for (const line of this.lines) {
      if (line.boundingRect.top < rect.top) {
        rect.top = line.boundingRect.top;
      }
      if (line.boundingRect.bottom > rect.bottom) {
        rect.bottom = line.boundingRect.bottom;
      }
      if (line.boundingRect.left < rect.left) {
        rect.left = line.boundingRect.left;
      }
      if (line.boundingRect.right > rect.right) {
        rect.right = line.boundingRect.right;
      }
    }
    return rect;
  }

  private draw(): void {
    for (const line of this.lines) {
      this.drawLine(line);
    }
    paper.view.update();
  }

  private drawLine(line: Line): void {
    const path = toPath(line, this.resizeFactor);
    this.simplifyPath(path, line.simplify);
  }

  private simplifyPath(path: paper.Path, level: number): void {
    if (level > 0) {
      path.simplify(level);
    }
  }
}
