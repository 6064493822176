import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { NotePageModule } from './note/note.module';
import { NoteSettingsService } from './note/services/note-settings.service';
import { NfTalesRouteReuseStrategy } from './core/strategies/nftales-route-reuse.strategy';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NotePageModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      deps: [NoteSettingsService],
      useFactory: (settings: NoteSettingsService) => (): Promise<void> =>
        settings.init(),
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: NfTalesRouteReuseStrategy },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
