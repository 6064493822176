import { Injectable } from '@angular/core';
import {
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  getDocs,
  query,
  QueryDocumentSnapshot,
  setDoc,
  Timestamp,
  updateDoc,
} from 'firebase/firestore';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { firesetore } from 'src/app/firebase.app';
import { Line, lineConverter, linesEqual } from '../../shared/definitions';
import { Note, notesRef } from '../definitions';
import { NoteService, NotesResponse } from './note.service';

@Injectable({
  providedIn: 'root',
})
export class LineService {
  constructor(private noteService: NoteService) {}

  async addLine(note: Note, line: Line): Promise<DocumentReference<Line>> {
    const snapshot = await this.noteService.loadNoteSnapshot(note.slug);
    if (!snapshot) {
      throw new Error(`firestore entry not found - ${note.slug}`);
    }
    const result = await this.addLines(snapshot.id, [line]);
    const noteRef = doc(notesRef, snapshot.id);
    const updated = Timestamp.now();
    note.updated = updated;
    await updateDoc(noteRef, { updated });
    return result[0];
  }

  async removeNoteLines(note: Note, lines: Line[]): Promise<void> {
    const snapshot = await this.noteService.loadNoteSnapshot(note.slug);
    if (!snapshot) {
      throw new Error(`firestore note entry not found - ${note.slug}`);
    }
    return await this.removeLines(snapshot.id, lines);
  }

  loadLines(noteId: string): Observable<Line[]> {
    const ref = collection(firesetore, `note/${noteId}/lines`);
    return from(getDocs(ref)).pipe(
      map((docs) => docs.docs.map((d) => lineConverter.fromFirestore(d)))
    );
  }

  async setLines(
    noteId: string,
    lines: Line[]
  ): Promise<DocumentReference<Line>[]> {
    await this.removeLines(noteId);
    return await this.addLines(noteId, lines);
  }

  loadPopulatedNoteBySlug(slug: string): Observable<Note> {
    return from(this.noteService.loadNoteSnapshot(slug)).pipe(
      switchMap((snapshot: QueryDocumentSnapshot<Note>) =>
        this.loadLines(snapshot.id).pipe(
          map((lines) => ({
            ...snapshot.data(),
            lines,
          }))
        )
      )
    );
  }

  async populateLines(note: Note): Promise<Note> {
    const snapshot = await this.noteService.loadNoteSnapshot(note.slug);
    if (!snapshot) {
      throw new Error('note not found');
    }
    const lines = await this.loadLines(snapshot.id).toPromise();
    return {
      ...note,
      lines,
    };
  }

  async populateNotesResponseLines(
    response: NotesResponse
  ): Promise<NotesResponse> {
    const fullNotes: Note[] = await Promise.all(
      response.data.map((note) => this.populateLines(note))
    );
    return {
      ...response,
      data: fullNotes,
    };
  }

  private async addLines(
    noteId: string,
    lines: Line[]
  ): Promise<DocumentReference<Line>[]> {
    const refs = [];
    for (const line of lines) {
      const ref = doc(
        collection(firesetore, `note/${noteId}/lines`)
      ).withConverter<Line>(lineConverter);
      await setDoc(ref, line);
      refs.push(ref);
    }
    return refs;
  }

  /**
   * @param noteId string
   * @param lines leave this option to remove all lines
   */
  private async removeLines(noteId: string, lines?: Line[]): Promise<void> {
    const ref = await query<Line>(
      collection(firesetore, `note/${noteId}/lines`).withConverter<Line>(
        lineConverter
      )
    );
    const docs = await getDocs<Line>(ref);
    for (const snapshot of docs.docs) {
      const line = snapshot.data();
      if (lines?.length !== 0 && !lines.find((l) => linesEqual(l, line))) {
        continue;
      }
      await deleteDoc(doc(firesetore, `note/${noteId}/lines/${snapshot.id}`));
    }
  }
}
