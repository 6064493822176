import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { delay, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  NoteSearchFilter,
  NoteService,
  NotesResponse,
} from '../../services/note.service';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
})
export class NoteListComponent implements OnInit, OnDestroy {
  @Input() limit = 30;
  @Input() enableSearchbar = true;

  @Output() notes$ = new Subject<NotesResponse>();

  notes: NotesResponse;

  searchForm = new FormGroup({
    title: new FormControl<string>(''),
  });

  private ngOnDestroy$ = new Subject<void>();

  constructor(private noteService: NoteService) {}

  get limitReached(): boolean {
    return this.notes?.total <= this.notes?.data.length;
  }

  ngOnInit(): void {
    this.loadNotes(1);
    this.searchForm
      .get('title')
      .valueChanges.pipe(
        takeUntil(this.ngOnDestroy$),
        tap(() => delete this.notes),
        delay(500),
        switchMap((title: string) => this.searchTitle(title))
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  loadNextPage(): Promise<void> {
    if (this.notes.data.length >= this.notes.total) {
      throw new Error('limit reached');
    }
    return this.loadNotes(this.notes.page + 1);
  }

  private searchTitle(title: string): Promise<void> {
    return this.loadNotes(1, { title });
  }

  private async loadNotes(
    page: number,
    search?: NoteSearchFilter
  ): Promise<void> {
    const response = await this.noteService
      .loadNotes({ page, limit: this.limit, search })
      .toPromise();

    if (!this.notes) {
      this.notes = response;
      this.notes$.next(this.notes);
      return;
    }

    this.notes = {
      ...response,
      data: this.notes.data.concat(response.data),
    };
    this.notes$.next(this.notes);
  }
}
