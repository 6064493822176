import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { NotePageRoutingModule } from './note-routing.module';

import { NotePageComponent } from './pages/note-page/note-page.component';
import { SharedModule } from '../shared/shared.module';
import { NoteSettingsComponent } from './components/note-settings/note-settings.component';
import { NoteSettingsModalComponent } from './modals/note-settings-modal/note-settings-modal.component';
import { NoteNewComponent } from './pages/note-new/note-new.component';
import { NoteEditComponent } from './components/note-edit/note-edit.component';
import { NoteEditModalComponent } from './modals/note-edit-modal/note-edit-modal.component';
import { NoteListComponent } from './components/note-list/note-list.component';
import { NoteListPageComponent } from './pages/note-list-page/note-list-page.component';
import { NotesSwipePageComponent } from './pages/notes-swipe-page/notes-swipe-page.component';
import { SwiperModule } from 'swiper/angular';
import { NoteToolbarComponent } from './components/note-toolbar/note-toolbar.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    NotePageRoutingModule,
    SharedModule,
    SwiperModule,
  ],
  declarations: [
    NotePageComponent,
    NoteSettingsComponent,
    NoteSettingsModalComponent,
    NoteNewComponent,
    NoteEditComponent,
    NoteEditModalComponent,
    NoteListComponent,
    NoteListPageComponent,
    NotesSwipePageComponent,
    NoteToolbarComponent,
  ],
  exports: [NoteListComponent],
})
export class NotePageModule {}
