import { Injectable } from '@angular/core';
import {
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAfter,
  where,
} from 'firebase/firestore';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  FIRESTORE_LIMIT_MAX,
  Note,
  noteConverter,
  notesRef,
} from '../definitions';

export interface NoteFilter {
  page?: number;
  limit?: number;
  orderBy?: keyof Note;
  orderDir?: 'asc' | 'desc';
  search?: NoteSearchFilter;
}

export interface NoteSearchFilter {
  slug?: string;
  title?: string;
}

export interface NotesResponse {
  data: Note[];
  page: number;
  limit: number;
  total: number;
}

@Injectable({
  providedIn: 'root',
})
export class NoteService {
  async createNote(note: Note): Promise<QueryDocumentSnapshot<Note>> {
    const ref = doc(notesRef).withConverter<Note>(noteConverter);
    await setDoc(ref, note);
    return await this.loadNoteSnapshot(note.slug);
  }

  async getTotal(search?: NoteSearchFilter): Promise<number> {
    const q = query(notesRef, ...this.getSearchFilters(search));
    const documentSnapshots = await getDocs(q);
    return documentSnapshots.size;
  }

  async loadNoteSnapshot(slug: string): Promise<QueryDocumentSnapshot<Note>> {
    const docs = await getDocs(
      query(notesRef, where('slug', '==', slug)).withConverter<Note>(
        noteConverter
      )
    );
    if (!docs?.docs?.length) {
      throw new Error('Not found');
    }
    return docs?.docs?.[0];
  }

  loadNotes(filter?: NoteFilter): Observable<NotesResponse> {
    const f = this.parseFilter(filter);
    const constraints: QueryConstraint[] = [];
    if (filter?.search) {
      constraints.push(...this.getSearchFilters(filter.search));
    }
    constraints.push(orderBy(f.orderBy, f.orderDir));
    let total = 0;

    return from(getDocs(query(notesRef, ...constraints))).pipe(
      map((snapshot: QuerySnapshot) => snapshot.size),
      tap((size: number) => (total = size)),
      switchMap(() => {
        const start = (f.page - 1) * f.limit;
        if (start > 0) {
          return from(this.getStartFilter(constraints, start)).pipe(
            tap((startFilter) => constraints.push(startFilter))
          );
        }
        return of(null);
      }),
      tap(() => constraints.push(limit(f.limit))),
      switchMap(() =>
        getDocs<Note>(
          query(notesRef, ...constraints).withConverter<Note>(noteConverter)
        )
      ),
      map((q: QuerySnapshot<Note>) => q.docs),
      map((docs: QueryDocumentSnapshot<Note>[]) => docs.map((d) => d.data())),
      map((data: Note[]) => ({
        data,
        page: f.page,
        limit: f.limit,
        total,
      }))
    );
  }

  findNoteIndex(slug: string, filter?: NoteFilter): Observable<number> {
    return from(
      this.loadNotes({
        ...filter,
        limit: FIRESTORE_LIMIT_MAX,
      })
    ).pipe(
      switchMap(async (response: NotesResponse) => {
        for (let i = 0; i < response.total; i++) {
          const note = response.data[i];
          if (note.slug === slug) {
            return i;
          }
        }
        return -1;
      })
    );
  }

  // eslint-disable-next-line complexity
  private parseFilter(filter?: NoteFilter): NoteFilter {
    return {
      limit: filter?.limit ?? 5,
      page: filter?.page ?? 1,
      orderBy: filter?.orderBy ?? 'created',
      orderDir: filter?.orderDir ?? 'desc',
    };
  }

  private async getStartFilter(
    constraints: QueryConstraint[],
    startAtNumber: number
  ): Promise<QueryConstraint> {
    const first = query(notesRef, ...constraints, limit(startAtNumber));
    const documentSnapshots = await getDocs(first);
    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];

    return startAfter(lastVisible);
  }

  private getSearchFilters(search: NoteSearchFilter): QueryConstraint[] {
    const constraints = [];
    if (search?.slug) {
      constraints.push(where('slug', '==', search.slug));
    }
    if (search?.title) {
      constraints.push(
        orderBy('title'),
        where('title', '>=', search.title),
        where('title', '<=', `${search.title}~`)
      );
    }
    return constraints;
  }
}
