import { Component, ViewChild } from '@angular/core';
import { IonInfiniteScroll } from '@ionic/angular';
import { NoteListComponent } from '../../components/note-list/note-list.component';

@Component({
  selector: 'app-note-list-page',
  templateUrl: './note-list-page.component.html',
})
export class NoteListPageComponent {
  @ViewChild(NoteListComponent) list: NoteListComponent;
  @ViewChild(IonInfiniteScroll) infinityScroll: IonInfiniteScroll;

  async onScrollEnd(event: any): Promise<void> {
    await this.list.loadNextPage();
    event.target.complete();
  }

  onNotesLoaded(): void {
    this.infinityScroll.disabled = this.list.limitReached;
  }
}
