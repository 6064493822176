import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-note-settings',
  templateUrl: './note-settings.component.html',
})
export class NoteSettingsComponent {
  @Input() settingsForm: FormGroup;
}
