import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-note-settings-modal',
  templateUrl: './note-settings-modal.component.html',
})
export class NoteSettingsModalComponent {
  @Input() settingsForm: FormGroup;

  constructor(public modalCtrl: ModalController) {}
}
