import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { from, Observable, of, Subject } from 'rxjs';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Swiper from 'swiper';
import { Note } from '../../definitions';
import { LineService } from '../../services/line.service';
import { NoteSettingsService } from '../../services/note-settings.service';
import { NoteService, NotesResponse } from '../../services/note.service';

@Component({
  selector: 'app-notes-swipe-page',
  templateUrl: './notes-swipe-page.component.html',
  styleUrls: ['./notes-swipe-page.component.scss'],
})
export class NotesSwipePageComponent implements OnInit, OnDestroy {
  notes$: Observable<NotesResponse>;
  current: Note;
  editAllowed = environment.edit;

  initialSlide = 0;

  slugIndexMap: { [slug: string]: number } = {};

  private swiper: Swiper;
  private ngOnDestroy$ = new Subject<void>();

  constructor(
    public settings: NoteSettingsService,
    private noteService: NoteService,
    private lineService: LineService,
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
    private toastCtrl: ToastController
  ) {}

  ngOnInit(): void {
    this.notes$ = this.loadFirstPage();
    this.settings.drawCanvasSettings.scrollPosY = 0;
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  initSwiper(swiper: Swiper): void {
    this.swiper = swiper;
  }

  onSlideChange(notes: NotesResponse, page: number): void {
    if (page === undefined) {
      throw new Error('slide index not found');
    }
    // use ngZone since swiper does not do this
    this.zone.run(() => {
      delete this.current;
      this.loadCurrentPage(notes, page);
    });
  }

  async onClickReloadPage(notes: NotesResponse): Promise<void> {
    const page = await this.findPageIndex(this.current.slug);
    notes.data[page] = undefined;
    delete this.current;
    await this.loadCurrentPage(notes, page);
  }

  private loadFirstPage(): Observable<NotesResponse> {
    return from(this.noteService.getTotal()).pipe(
      // leave undefined as a placeholder for all notes that are not loaded yet
      map((total) => {
        const data = [];
        for (let i = 0; i < total; i++) {
          data.push(undefined);
        }
        return {
          total,
          data,
          page: 1,
          limit: 1,
        };
      }),
      switchMap(async (response) => {
        const slug = this.route.snapshot.params.note;
        if (slug) {
          this.initialSlide = await this.findPageIndex(slug);
        }
        return response;
      }),
      switchMap((response) => {
        if (this.initialSlide > 0) {
          return of(response);
        }
        return from(this.loadCurrentPage(response, 0)).pipe(
          map(() => response)
        );
      }),
      tap(() => this.initRouting())
    );
  }

  private async loadCurrentPage(
    notes: NotesResponse,
    page: number
  ): Promise<void> {
    this.current = await this.loadPage(notes, page);
    this.updateRouting(this.current);
    if (notes.total - 1 <= page) {
      return;
    }
    await this.loadPage(notes, page + 1);
    if (page < 1) {
      return;
    }
    await this.loadPage(notes, page - 1);
  }

  private async loadPage(notes: NotesResponse, page: number): Promise<Note> {
    const current = notes.data[page];
    if (current) {
      return current;
    }
    try {
      const response = await this.noteService
        .loadNotes({ limit: 1, page: page + 1 })
        .toPromise();
      const note = await this.lineService.populateLines(response.data[0]);
      notes.data[page] = note;
      this.slugIndexMap[note.slug] = page;
      return note;
    } catch (e) {
      const toast = await this.toastCtrl.create({
        duration: 2000,
        header: `Error while loading page - ${page}/${notes?.total}`,
        message: e.message,
      });
      await toast.present();
      throw e;
    }
  }

  private async findPageIndex(slug: string): Promise<number> {
    if (this.slugIndexMap[slug] !== undefined) {
      return this.slugIndexMap[slug];
    }
    return await this.noteService.findNoteIndex(slug).toPromise();
  }

  private updateRouting(note: Note): void {
    this.router.navigate(['/note', note.slug]);
  }

  private initRouting(): void {
    this.route.params
      .pipe(
        takeUntil(this.ngOnDestroy$),
        switchMap((value: Params) => this.findPageIndex(value.note)),
        tap((page: number) => this.swiper?.slideTo(page))
      )
      .subscribe();
  }
}
