import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonContent, ToastController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { Line, Tool } from '../../../shared/definitions';
import { Note } from '../../definitions';
import { HistoryService } from '../../services/history.service';
import { LineService } from '../../services/line.service';
import { NoteSettingsService } from '../../services/note-settings.service';

@Component({
  selector: 'app-note',
  templateUrl: './note-page.component.html',
  styleUrls: ['./note-page.component.scss'],
  providers: [HistoryService],
})
export class NotePageComponent implements OnInit {
  @ViewChild(IonContent) content: IonContent;

  noteId: string;

  note$: Observable<Note>;

  private redrawTimeout;

  constructor(
    public settingsService: NoteSettingsService,
    public historyService: HistoryService,
    private activatedRoute: ActivatedRoute,
    private toastCtrl: ToastController,
    private lineService: LineService
  ) {}

  @HostListener('window:resize')
  onResize(): void {
    this.redraw();
  }

  ngOnInit(): void {
    this.noteId = this.activatedRoute.snapshot.paramMap.get('note');
    this.note$ = this.loadNote().pipe(
      map((note: Note) => (environment.edit ? note : { ...note, locked: true }))
    );
    this.settingsService.drawCanvasSettings.scrollPosY = 0;
  }

  async onClickBackButton(note: Note): Promise<void> {
    await this.historyService.back(note);
    this.redraw();
  }

  async onClickForwardButton(note: Note): Promise<void> {
    await this.historyService.forward(note);
    this.redraw();
  }

  onLineAdded(note: Note, line: Line): void {
    this.lineService.addLine(note, line);
    this.historyService.push({ type: Tool.PENCIL, lines: [line] });
  }

  async onLineRemoved(note: Note, removeLines: Line[]): Promise<void> {
    if (!removeLines?.length) {
      return;
    }
    try {
      await this.lineService.removeNoteLines(note, removeLines);
      this.historyService.push({ type: Tool.ERASER, lines: removeLines });
    } catch (e) {
      await this.showErrorToast('Line could not be removed', e);
    }
  }

  redraw(): void {
    delete this.note$;
    clearTimeout(this.redrawTimeout);
    this.redrawTimeout = setTimeout(
      () =>
        (this.note$ = this.loadNote().pipe(
          tap(() => setTimeout(() => this.resetScroll(), 200))
        )),
      20
    );
  }

  private loadNote(): Observable<Note> {
    return this.lineService.loadPopulatedNoteBySlug(this.noteId).pipe(
      catchError((error) => {
        this.showErrorToast('Could not load note', error);
        return throwError(error);
      })
    );
  }

  private resetScroll(): void {
    this.content.scrollToPoint(
      0,
      this.settingsService.drawCanvasSettings.scrollPosY
    );
  }

  private async showErrorToast(header: string, error): Promise<void> {
    const toast = await this.toastCtrl.create({
      header,
      message: error.message,
      duration: 2000,
    });
    toast.present();
  }
}
