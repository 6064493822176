import { Injectable } from '@angular/core';
import { Line, Tool } from '../../shared/definitions';
import { Note } from '../definitions';
import { LineService } from './line.service';

export interface HistoryEntry {
  type: Tool.PENCIL | Tool.ERASER;
  lines: Line[];
}

@Injectable()
export class HistoryService {
  private backStack: HistoryEntry[] = [];
  private forwardStack: HistoryEntry[] = [];

  constructor(private lineService: LineService) {}

  get canGoBack(): boolean {
    return !!this.backStack.length;
  }

  get canGoForward(): boolean {
    return !!this.forwardStack.length;
  }

  push(entry: HistoryEntry): void {
    this.backStack.push(entry);
    this.forwardStack = [];
  }

  async back(note: Note): Promise<HistoryEntry> {
    const entry = this.backStack.pop();
    if (entry.type === Tool.PENCIL) {
      await this.lineService.removeNoteLines(note, entry.lines);
    } else if (entry.type === Tool.ERASER) {
      for (const line of entry.lines) {
        await this.lineService.addLine(note, line);
      }
    }
    this.forwardStack.push(entry);
    return entry;
  }

  async forward(note: Note): Promise<HistoryEntry> {
    const entry = this.forwardStack.pop();
    if (entry.type === Tool.PENCIL) {
      for (const line of entry.lines) {
        await this.lineService.addLine(note, line);
      }
    } else if (entry.type === Tool.ERASER) {
      await this.lineService.removeNoteLines(note, entry.lines);
    }
    this.backStack.push(entry);
    return entry;
  }
}
