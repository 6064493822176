import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';
import { getIcon, possibleIcons } from '../../definitions';
import { NoteService } from '../../services/note.service';

@Component({
  selector: 'app-note-edit',
  templateUrl: './note-edit.component.html',
  styleUrls: ['./note-edit.component.scss'],
})
export class NoteEditComponent implements OnInit {
  @Input() slug: string;
  @Input() title: string;

  form: FormGroup;

  icons = possibleIcons;

  constructor(private noteService: NoteService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      slug: new FormControl(
        this.slug,
        [
          Validators.required,
          Validators.minLength(5),
          Validators.pattern(/^[a-z0-9-]+$/),
        ],
        [
          (control: AbstractControl): Observable<ValidationErrors | null> =>
            of(control.value).pipe(
              delay(2000),
              switchMap((value: string) => this.isSlugTaken(value)),
              map((taken: boolean) => (taken ? { slugTaken: true } : null))
            ),
        ]
      ),
      title: new FormControl(this.title, [
        Validators.required,
        Validators.minLength(5),
      ]),
      icon: new FormControl(undefined, [
        Validators.required,
        (control: AbstractControl): ValidationErrors | null => {
          if (possibleIcons.includes(control.value)) {
            return null;
          }
          return {
            invalidIcon: true,
          };
        },
      ]),
    });
  }

  onTitleChange(event: Event): void {
    if (this.form.get('slug').touched || !(event instanceof CustomEvent)) {
      return;
    }
    const title: string = event.detail.value;
    const slug = title
      .toLowerCase()
      .split(/[^a-z0-9-]/)
      .filter((part) => !!part)
      .join('-');
    this.form.get('slug').setValue(slug);
  }

  onSlugChange(event: Event): void {
    if (this.form.get('icon').touched || !(event instanceof CustomEvent)) {
      return;
    }
    const slug: string = event.detail.value;
    this.form.get('icon').setValue(getIcon(slug));
  }

  private isSlugTaken(value: string): Observable<boolean> {
    return this.noteService
      .loadNotes({ limit: 1, search: { slug: value } })
      .pipe(map((response) => !!response.total));
  }
}
