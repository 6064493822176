import { Component, Input, ViewChild } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { NoteEditComponent } from '../../components/note-edit/note-edit.component';

@Component({
  selector: 'app-note-edit-modal',
  templateUrl: './note-edit-modal.component.html',
})
export class NoteEditModalComponent {
  @ViewChild('edit') edit: NoteEditComponent;

  @Input() modalTitle: string;

  @Input() slug: string;
  @Input() title: string;

  constructor(
    public modalCtrl: ModalController,
    private toastCtrl: ToastController
  ) {}

  onClickSave(): void {
    if (this.edit.form.pending) {
      this.toastError('Please wait while the url is checked.');
      return;
    }
    if (!this.edit.form.valid) {
      this.toastError('Invalid input');
      return;
    }
    this.modalCtrl.dismiss(
      {
        slug: this.edit.form.get('slug').value,
        title: this.edit.form.get('title').value,
        icon: this.edit.form.get('icon').value,
      },
      'save'
    );
  }

  private async toastError(message: string): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      duration: 1000,
    });
    await toast.present();
  }
}
