import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Timestamp } from 'firebase/firestore';
import { Subject } from 'rxjs';
import { Line, Tool } from '../../../shared/definitions';
import { Note } from '../../definitions';
import { NoteEditModalComponent } from '../../modals/note-edit-modal/note-edit-modal.component';
import { LineService } from '../../services/line.service';
import { NoteSettingsService } from '../../services/note-settings.service';
import { NoteService } from '../../services/note.service';

@Component({
  selector: 'app-note-new',
  templateUrl: './note-new.component.html',
  styleUrls: ['./note-new.component.scss'],
})
export class NoteNewComponent implements OnInit, OnDestroy {
  note: Note;

  tools = Tool;

  private ngOnDestroy$ = new Subject<void>();
  private redrawTimeout;

  constructor(
    public settingsService: NoteSettingsService,
    private modalCtrl: ModalController,
    private router: Router,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private noteService: NoteService,
    private lineService: LineService
  ) {}

  @HostListener('window:resize')
  onResize(): void {
    this.redraw();
  }

  async clearNote(): Promise<void> {
    this.note = { lines: [] };
    this.redraw();
  }

  async onClickSave(lines: Line[]): Promise<void> {
    const modal = await this.modalCtrl.create({
      component: NoteEditModalComponent,
      componentProps: {
        modalTitle: 'Save Note',
      },
    });
    await modal.present();
    const result = await modal.onDidDismiss();
    if (result.role !== 'save') {
      return;
    }
    this.openNewNote(
      result.data.slug,
      result.data.title,
      result.data.icon,
      lines
    );
  }

  ngOnInit(): void {
    this.note = { lines: [] };
    this.settingsService.drawCanvasSettings.scrollPosY = 0;
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  redraw(): void {
    const note = this.note;
    delete this.note;
    clearTimeout(this.redrawTimeout);
    this.redrawTimeout = setTimeout(() => (this.note = note), 100);
  }

  private async openNewNote(
    slug: string,
    title: string,
    icon: string,
    lines: Line[]
  ): Promise<void> {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    try {
      // save to firestore
      const snapshot = await this.noteService.createNote({
        slug,
        title,
        icon,
        lines,
        created: Timestamp.now(),
        updated: Timestamp.now(),
        locked: false,
      });
      await this.lineService.setLines(snapshot.id, lines);
      await this.clearNote();
      // navigate to new note
      await this.router.navigate(['/note', slug]);
      // show success toast
      const alert = await this.alertCtrl.create({
        header: 'Note was saved',
        buttons: [{ text: 'Ok', handler: (): void => location.reload() }],
      });
      await alert.present();
      await loading.dismiss();
    } catch (err) {
      const alert = await this.alertCtrl.create({
        header: 'An Error occured',
        message: err.message,
        buttons: [{ text: 'Ok' }],
      });
      await alert.present();
      await loading.dismiss();
    }
  }
}
