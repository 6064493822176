import { Component } from '@angular/core';
import { NoteSettingsService } from '../../services/note-settings.service';

@Component({
  selector: 'app-note-toolbar',
  templateUrl: './note-toolbar.component.html',
  styleUrls: ['./note-toolbar.component.scss'],
})
export class NoteToolbarComponent {
  constructor(public settings: NoteSettingsService) {}
}
