import { FirebaseApp, FirebaseOptions, initializeApp } from 'firebase/app';
import { Firestore, getFirestore } from 'firebase/firestore';

export const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyA-vlhIye3JnlQof1hc0sS1KO_RH3ySigA',
  authDomain: 'nftales-a3d7c.firebaseapp.com',
  projectId: 'nftales-a3d7c',
  storageBucket: 'nftales-a3d7c.appspot.com',
  messagingSenderId: '882426899946',
  appId: '1:882426899946:web:44bbe009453327ca771693',
};

// Initialize Firebase
export const app: FirebaseApp = initializeApp(firebaseConfig);
// Initialize Cloud Firestore and get a reference to the service
export const firesetore: Firestore = getFirestore(app);
